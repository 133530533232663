
.loading svg polyline {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .loading svg polyline#back {
    fill: none;
    stroke: #ff4d5033;
  }
  
  .loading svg polyline#front {
    fill: none;
    stroke: #ff4d4f;
    stroke-dasharray: 48, 144;
    stroke-dashoffset: 192;
    animation: dash_682 1.4s linear infinite;
  }
  
  @keyframes dash_682 {
    72.5% {
      opacity: 0;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  /*Loader for nft img home */
  .LoaderNftImage {
    position: relative;
    width: 54px;
    height: 54px;
    border-radius: 10px;
  }
  
  .LoaderNftImage div {
    width: 8%;
    height: 24%;
    background: rgb(255, 247, 247);
    position: absolute;
    left: 50%;
    top: 30%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    animation: fade458 1s linear infinite;
  }
  
  @keyframes fade458 {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0.25;
    }
  }
  
  .LoaderNftImage .bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
  }
  
  .LoaderNftImage .bar2 {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -1.1s;
  }
  
  .LoaderNftImage .bar3 {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -1s;
  }
  
  .LoaderNftImage .bar4 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.9s;
  }
  
  .LoaderNftImage .bar5 {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.8s;
  }
  
  .LoaderNftImage .bar6 {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.7s;
  }
  
  .LoaderNftImage .bar7 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.6s;
  }
  
  .LoaderNftImage .bar8 {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.5s;
  }
  
  .LoaderNftImage .bar9 {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.4s;
  }
  
  .LoaderNftImage .bar10 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.3s;
  }
  
  .LoaderNftImage .bar11 {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.2s;
  }
  
  .LoaderNftImage .bar12 {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.1s;
  }


  /* signal loader */
  .loader-signal {
    position: relative;
    width: 150px;
    height: 150px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 25px 25px 75px rgba(0,0,0,0.55);
    border: 1px solid #333;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .loader-signal::before {
    content: '';
    position: absolute;
    inset: 20px;
    background: transparent;
    border: 1px dashed#444;
    border-radius: 50%;
    box-shadow: inset -5px -5px 25px rgba(0,0,0,0.25),
    inset 5px 5px 35px rgba(0,0,0,0.25);
  }
  
  .loader-signal::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px dashed#444;
    box-shadow: inset -5px -5px 25px rgba(0,0,0,0.25),
    inset 5px 5px 35px rgba(0,0,0,0.25);
  }
  
  .loader-signal span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 100%;
    background: transparent;
    transform-origin: top left;
    animation: radar81 2s linear infinite;
    border-top: 1px dashed #fff;
  }
  
  .loader-signal span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: seagreen;
    transform-origin: top left;
    transform: rotate(-55deg);
    filter: blur(30px) drop-shadow(20px 20px 20px seagreen);
  }
  
  @keyframes radar81 {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  

  /* SPINNER */
  .spinner {
    border: 2px solid #fff;
    border-left-color: transparent;
    border-radius: 50%;
  }
  
  .spinner {
    border: 2px solid #fff;
    border-left-color: transparent;
    width: 20px;
    height: 20px;
  }
  
  .spinner {
    border: 2px solid #fff;
    border-left-color: transparent;
    width: 20px;
    height: 20px;
    animation: spin89345 1s linear infinite;
  }
  
  @keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }