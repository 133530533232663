@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.container {
  text-align: center;
  padding: 20px;
}

.heading {
  font-size: 4rem;
  margin-bottom: 10px;
  color: white;
  letter-spacing: 4px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}

.subheading {
  color: #30eb20;
  font-size: 2rem;
  margin-bottom: 20px;
  letter-spacing: 4px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
  gap: 20px;
  justify-items: center;
  margin-top: 3rem;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Slightly more opaque background */
  padding: 20px;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly larger shadow */
  width: 100%;
  max-width: 320px; /* Slightly wider max width */
  gap: 1.5rem; /* Increased gap between elements */
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.card:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Rounded corners for images */
}

.cardTitle {
  font-size: 1.4rem; /* Slightly larger font size */
  margin-bottom: 12px; /* Increased margin */
  color: #e2ac19; /* Gold color for titles */
  font-weight: 700; /* Bold title */
}

.cardDescription {
  font-size: 1.1rem; /* Slightly larger font size */
  color: #ffffff; /* White color for description */
  text-align: center; /* Center-align text */
}

.searchBar {
  width: 100%;
}

.searchBar input[type="text"] {
  margin-bottom: 10px;
  padding: 0.6rem;
  width: 60%;
  border: 1px solid #2f3241;
  border-radius: 2rem;
  background-color: #2f3241;
  color: #ffffff;
}

input::placeholder {
  font-weight: 900;
  color: white;
  padding-left: 1rem;
}

/* Existing icon container */
.iconContainer {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: transform 0.3s ease, fill 0.3s ease;
}

.iconContainer:hover {
  transform: scale(1.1);
}

/* New delete icon container */
.deleteIcon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px; /* Ensure it's aligned with the top-right corner */
  width: 24px;
  height: 24px;
  color: lightcoral;
  /* background-image: url('/path/to/delete-icon.svg'); Add the delete icon image here */
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.deleteIcon:hover {
  transform: scale(1.2); /* Slightly enlarge on hover */
  opacity: 0.8; /* Add opacity effect on hover */
}

.selectAllButton {
  background-color: #3a4956; /* Green background */
  color: white; /* White text */
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.selectAllButton:hover {
  background-color: #45a049; /* Darker green on hover */
}

.viewMoreButtonContainer {
  text-align: center;
  margin-top: 2rem;
}

.viewMoreButton {
  background-color: #3a4956; /* Background color for button */
  color: white; /* Text color */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viewMoreButton:hover {
  background-color: #45a049; /* Darker background on hover */
}

/* Add this to MarketRaker.module.css */

.loadingMessage {
  font-size: 18px;
  color: #555;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  position: relative;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light gray border */
  border-left: 4px solid #3498db; /* Blue color for the spinner */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 375px) {
  .heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1.5rem;
  }

  .grid {
    grid-template-columns: 1fr; /* 1 card per row */
  }

  .searchBar input[type="text"] {
    width: 90%;
  }

  .card {
    max-width: 100%;
    min-width: 100%;
    max-height: auto; /* Remove fixed height */
    min-height: auto; /* Remove fixed height */
  }

  .card img {
    max-height: 80px;
    min-height: 80px;
  }

  .cardTitle {
    font-size: 1rem;
  }

  .cardDescription {
    font-size: 0.9rem;
  }

  .selectAllButton,
  .viewMoreButton {
    width: 90%;
  }
}

/* Small tablets and larger mobile screens */
@media (min-width: 376px) and (max-width: 426px) {
  .heading {
    font-size: 2.5rem;
  }

  .subheading {
    font-size: 1.8rem;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }

  .searchBar input[type="text"] {
    width: 80%;
  }


  .card {
    max-width: 100%;
    min-width: 100%;
    display: flex; /* Ensure card content is displayed flexibly */
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
    padding: 10px; /* Padding for card content */
    gap: 0rem;
  }
  .card img {
    max-width: 100%; /* Ensure image does not exceed card width */
    height: auto; /* Allow height to adjust automatically */
    max-height: 90px; /* Cap height to avoid overflowing */
    object-fit: cover; /* Cover the container while preserving aspect ratio */
  }

  .cardTitle {
    font-size: 1.2rem;
  }

  .cardDescription {
    font-size: 0.6rem;
  }

  .selectAllButton,
  .viewMoreButton {
    width: 80%;
  }
}

/* Tablets and small laptops */
@media (min-width: 427px) and (max-width: 767px) {
  .heading {
    font-size: 3rem;
  }

  .subheading {
    font-size: 2rem;
  }

  .grid {
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  }

  .searchBar input[type="text"] {
    width: 70%;
  }

  .card {
    max-width: 100%;
    min-width: 100%;
    display: flex; /* Ensure card content is displayed flexibly */
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
    padding: 10px; /* Padding for card content */
    gap: 0rem;
  }

  .card img {
    max-width: 100%; /* Ensure image does not exceed card width */
    height: auto; /* Allow height to adjust automatically */
    max-height: 100px; /* Cap height to avoid overflowing */
    object-fit: cover; /* Cover the container while preserving aspect ratio */
  }

  .cardTitle {
    font-size: 1.3rem;
  }

  .cardDescription {
    font-size: 1.1rem;
  }

  .selectAllButton,
  .viewMoreButton {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .heading {
    font-size: 3.5rem;
  }

  .subheading {
    font-size: 2.2rem;
  }

  .grid {
    grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
  }

  .searchBar input[type="text"] {
    width: 60%;
  }

  .card {
    max-width: 100%;
    min-width: 100%;
    display: flex; /* Ensure card content is displayed flexibly */
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
    padding: 10px; /* Padding for card content */
    gap: 0rem;
  }

  .card img {
    max-width: 100%; /* Ensure image does not exceed card width */
    height: auto; /* Allow height to adjust automatically */
    max-height: 100px; /* Cap height to avoid overflowing */
    object-fit: cover; /* Cover the container while preserving aspect ratio */
  }

  .cardTitle {
    font-size: 1.4rem;
  }

  .cardDescription {
    font-size: 0.8rem;
  }

  .selectAllButton,
  .viewMoreButton {
    width: 60%;
  }
}

