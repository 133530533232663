#dexhunter-container img:nth-of-type(1) {
  max-width: 60px !important;
  max-height: 60px !important;
}

#dexhunter-container img:nth-of-type(2) {
  width: auto !important;
  height: auto !important;
}

#dexhunter-container img,
#dexhunter-container video {
  margin: 0 !important;
  padding: 0 !important;
  object-fit: contain;
}
