/* Base styles */
.video-player-page {
  display: flex;
  flex-direction: row;
  padding: 3rem;
  gap: 2rem;
  /* background: #333; Dark background for better contrast */
}

.main-video-player {
  flex: 2;
}

.video-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.video-player video {
  width: 100%;
  height: 35rem;
  border-radius: 8px;
  margin-bottom: 20px;
  background: #000; /* Ensures the video area is dark */
}

.categories-list {
  flex: 1;
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 8px;
  color: #333; /* Dark text color for better readability */
}

.category-section {
  margin-bottom: 2rem;
}

.category-section h2 {
  color: #333; /* Dark text color */
  background: #fff; /* Light background for section headers */
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
  text-align: center; /* Center align the header text */
}

.category-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-section li {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  background: #fff; /* White background for cards */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* Ensures the rounded corners are respected */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
}

.category-section li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.category-section a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
}

.thumbnail-wrapper {
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
  transition: transform 0.3s ease; /* Smooth zoom effect */
  border-radius: 0 !important;
}

.category-section li:hover .thumbnail-image {
  transform: scale(1.05); /* Zoom effect on hover */
}

.category-section h3 {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: #333; /* Dark text color */
  padding: 0 1rem; /* Padding for spacing */
}

.category-section p {
  margin: 0;
  color: #555; /* Lighter text color for description */
  padding: 0 1rem; /* Padding for spacing */
}
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; /* Number of lines to show */
  height: 4.5em; /* Adjust height as needed */
}
/* Responsive styles */
@media (max-width: 375px) {
  .video-player-page {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .video-player video {
    height: 15rem;
  }

  .category-section h2 {
    font-size: 1rem;
  }

  .category-section img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 376px) and (max-width: 426px) {
  .video-player-page {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .video-player video {
    height: 20rem;
  }

  .category-section h2 {
    font-size: 1.1rem;
  }

  .category-section img {
    width: 100%;
  }
}

@media (min-width: 427px) and (max-width: 767px) {
  .video-player-page {
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .video-player video {
    height: 25rem;
  }

  .category-section h2 {
    font-size: 1.2rem;
  }

  .category-section img {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .video-player-page {
    flex-direction: row;
    padding: 2rem;
    gap: 2rem;
  }

  .video-player video {
    height: 30rem;
  }

  .category-section h2 {
    font-size: 1.2rem;
  }

  .category-section img {
    width: 150px;
  }
}
