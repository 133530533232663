/* AddNewCourse.module.css */
.OuterDiv {
  padding: 7rem 15rem;
}

.addNewCourseOuterDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  gap: 1rem;
}

.addNewVideoBtn {
  position: relative;
  padding: 1rem 2rem;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 1rem;
  border-radius: 2rem;
  letter-spacing: 0.2rem;
  font-weight: 800;
  width: 40rem;
  margin-top: 2rem;
}

.addNewVideoBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2rem;
  padding: 2px;
  background: linear-gradient(to right, #342af1, #303f71);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.addPreview {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #2f3241;
  border-radius: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 35rem;
  border: 1px solid #ffffff;
}

input[type="text"] {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid #2f3241;
  border-radius: 4px;
  background-color: #2f3241;
  color: #ffffff;
}

.TitleAndDescriptionDiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title input[type="text"] {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 1rem;
  margin-bottom: 20px;
  background-color: #2f3241;
  color: #ffffff;
  padding: 1rem;

}
.description {
  height: 100%;
}
.description textarea {
  width: 100%;
  height: 100%;
  padding: 2rem;
  border: 1px solid #ffffff;
  border-radius: 2rem;
  background-color: #2f3241;
  color: #ffffff;
  resize: none; /* Prevents resizing of the textarea */
  box-sizing: border-box; /* Ensures padding is included in the width/height */
}

textarea::placeholder {
  color: #ffffff;
}

.plusSvg {
  cursor: pointer;
}
