.progressContainer {
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bull {
  background-color: #06a036;
  color: black;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 1rem;
}

.bear {
  background-color: #d11b1b;
  color: black;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 1rem;
}
