/* Base styles */
.home-page {
  padding: 2rem 7rem;
  background-color: #1c1c1c; /* Optional: Set a background color for contrast */
}

.home-page h1 {
  color: white;
  font-size: 2rem;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.addNewCourseBtn {
  position: relative;
  padding: 1rem 2rem;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 1rem;
  border-radius: 2rem;
  letter-spacing: 0.2rem;
  font-weight: 800;
  transition: background-color 0.3s, transform 0.3s;
}

.addNewCourseBtn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}

.addNewCourseBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2rem;
  padding: 2px;
  background: linear-gradient(to right, #342af1, #303f71);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.headingAndNewCourseBtnDiv {
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
}

.course-list {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 4rem;
}

.course-item {
  flex: 0 0 auto;
  /* margin-right: 2rem; */
  color: white;
  transition: transform 0.3s, box-shadow 0.3s;
  min-width: 20rem;
  max-width: 20rem;
}

.course-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.course-item img {
  width: 20.5rem;
  height: 12rem; /* Set a fixed height */
  border-radius: 0.5rem;
  transition: opacity 0.3s;
  object-fit: fill;
}

.course-item img:hover {
  opacity: 0.8;
}

.course-item h3 {
  font-size: 1.2rem;
  margin: 10px 0;
}

.course-item p {
  font-size: 0.9rem;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  gap: 15px;
  color: white;
}

.video-item {
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: center;
}

.video-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.video-item img {
  width: 20.5rem;
  height: 12rem;
  border-radius: 0.5rem;
  transition: opacity 0.3s;
}

.video-item img:hover {
  opacity: 0.8;
}

.video-item h3 {
  font-size: 1.2rem;
  margin: 10px 0;
}

.video-item p {
  font-size: 0.9rem;
}
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; /* Number of lines to show */
  height: 4.5em; /* Adjust height as needed */
}
/* Responsive styles */
@media (max-width: 375px) {
  .home-page {
    padding: 0.5rem;
  }

  .home-page h1 {
    font-size: 1.2rem;
    text-align: center;
  }

  .addNewCourseBtn {
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 1rem;
  }

  .headingAndNewCourseBtnDiv {
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
  }

  .course-list {
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
  }

  .course-item {
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 21rem;
    text-align: center;
    margin-right: 2rem;
  }

  .course-item img {
    width: 100%;
    /* height: auto; */
  }

  .course-item h3 {
    font-size: 0.9rem;
  }

  .course-item p {
    font-size: 0.7rem;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }

  .video-item h3 {
    font-size: 0.9rem;
  }

  .video-item p {
    font-size: 0.7rem;
  }
}

@media (min-width: 376px) and (max-width: 426px) {
  .home-page {
    padding: 1rem;
  }

  .home-page h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .addNewCourseBtn {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 1.5rem;
  }

  .headingAndNewCourseBtnDiv {
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .course-list {
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
  }

  .course-item {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 20rem;
    text-align: center;
  }

  .course-item img {
    width: 100%;
    /* height: auto; */
  }

  .course-item h3 {
    font-size: 1rem;
  }

  .course-item p {
    font-size: 0.8rem;
  }

  .video-grid {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .video-item img {
    width: 100%;
  }
  .video-item h3 {
    font-size: 1rem;
  }

  .video-item p {
    font-size: 0.8rem;
  }
}

@media (min-width: 427px) and (max-width: 767px) {
  .home-page {
    padding: 1.5rem;
  }

  .home-page h1 {
    font-size: 1.75rem;
    text-align: left;
  }

  .addNewCourseBtn {
    padding: 0.875rem 1.75rem;
    font-size: 0.95rem;
    border-radius: 1.75rem;
  }

  .headingAndNewCourseBtnDiv {
    margin-bottom: 3rem;
  }

  .course-list {
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
  }

  .course-item {
    margin-right: 20px;
    width: auto;
    max-width: 22rem;
    text-align: left;
  }

  .course-item img {
    width: 100%;
    /* height: auto; */
  }

  .course-item h3 {
    font-size: 1.1rem;
  }

  .course-item p {
    font-size: 0.85rem;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }

  .video-item h3 {
    font-size: 1.1rem;
  }

  .video-item p {
    font-size: 0.85rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home-page {
    padding: 2rem;
  }

  .home-page h1 {
    font-size: 2rem;
    text-align: left;
  }

  .addNewCourseBtn {
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 2rem;
  }

  .headingAndNewCourseBtnDiv {
    margin-bottom: 4rem;
    flex-direction: row;
    align-items: center;
  }

  .course-list {
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
  }

  .course-item {
    margin-right: 15px;
    width: auto;
    max-width: 22rem;
    text-align: left;
  }

  .course-item img {
    width: 100%;
    /* height: auto; */
  }

  .course-item h3 {
    font-size: 1.2rem;
  }

  .course-item p {
    font-size: 0.9rem;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }

  .video-item h3 {
    font-size: 1.2rem;
  }

  .video-item p {
    font-size: 0.9rem;
  }
}
